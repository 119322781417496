<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-user', params: { entityType: entityType } }">
    <template #title>
      {{ translateTitleCase("core.user.editTitle") }}
    </template>

    <template #default>
      <crud-edit
        :api-get-item-url="'user/' + userId"
        :form-path="'form/user-user'"
        :form-action="'user/' + userId"
        :back-route="{ name: 'admin-user' }"
        :success-redirect="{ name: 'admin-user' }"
        @error="handleError">
      </crud-edit>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import CrudEdit from "@/client/components/crud/Edit.vue";
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n/index";

export default {
  components: { CrudEdit },
  props: {
    userId: {
      type: String,
      default: false,
    },
  },
  setup(props, setupContext) {
    let { t } = useI18n();
    let { meta } = useMeta({
      title: t("admin.user.editView.title"),
    });

    return { meta };
  },
  data: function () {
    return {};
  },
  methods: {
    handleError({ context, data }) {
      console.log(context, data);

      // we have special treatment for validation error form server
      if (data.code != 422) {
        return;
      }

      if (data.data.email) {
        this.$s.ui.notification(this.translate(data.data.email), "danger");
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
